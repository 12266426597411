"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
const ngRightClick_1 = require("@src/app/directives/ngRightClick");
const defaultSize = 11;
const defaultVersion = 1;
class Tile {
    constructor(connections, isServer, isTerminal, powered) {
        this.position = [0, 0];
        this._connections = connections; // an array of 4 bools, north east south west
        this._isServer = isServer;
        this._isTerminal = isTerminal;
        this._powered = powered;
    }
    get connections() {
        return this._connections;
    }
    get isServer() {
        return this._isServer;
    }
    get isTerminal() {
        return this._isTerminal;
    }
    get powered() {
        return this._powered;
    }
    set connections(e) {
        this._connections = e;
    }
    set isServer(e) {
        this._isServer = e;
    }
    set isTerminal(e) {
        this._isTerminal = e;
    }
    set powered(e) {
        this._powered = e;
    }
}
function randint(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}
function getBaseLog(x, y) {
    return Math.log(y) / Math.log(x);
}
const sizeInitial = function (value) {
    let numValue = parseInt(value || defaultSize) || defaultSize;
    if ([7, 11, 15, 17, 19, 21, 23, 25].indexOf(numValue) < 0) {
        numValue = defaultSize;
    }
    return numValue;
};
const versionInitial = function (value) {
    let numValue = parseInt(value || defaultVersion) || defaultVersion;
    if ([1, 2].indexOf(numValue) < 0) {
        numValue = defaultVersion;
    }
    return numValue;
};
class NetwalkCtrl {
    constructor($scope, $timeout, $q, $location, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$q = $q;
        this.$location = $location;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.size = sizeInitial(this.$location.search()['size']);
        this.version = versionInitial(this.$location.search()['version']);
        this.grid = [];
        this.connected = 1;
        this.gridInitial = [];
        this.gridResolved = [];
        this.empty = [];
        this.tips = [];
        this.stack = [];
        this.moves = 0;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timerWork = false;
        this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
        this.gameFinish = false;
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!localStorage.getItem(`${this.constructor.name}_game`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_game`);
                        localStorage.removeItem(`${this.constructor.name}_startWith`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    $onInit() {
        const savedData = JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || 'null');
        if (!savedData) {
            this.newGame();
        }
        else {
            this.grid = savedData.grid.map((item) => {
                const tile = new Tile(item._connections, item._isServer, item._isTerminal, item._powered);
                tile.pathInfo = item.pathInfo;
                tile.position = item.position;
                return tile;
            });
            this.gridInitial = savedData.gridInitial.map((item) => {
                const tile = new Tile(item._connections, item._isServer, item._isTerminal, item._powered);
                tile.pathInfo = item.pathInfo;
                tile.position = item.position;
                return tile;
            });
            this.gridResolved = savedData.gridResolved.map((item) => {
                const tile = new Tile(item._connections, item._isServer, item._isTerminal, item._powered);
                tile.pathInfo = item.pathInfo;
                tile.position = item.position;
                return tile;
            });
            this.size = savedData.size;
            this.version = savedData.version;
            this.moves = savedData.moves;
            this.connected = this.grid.filter((item) => item._powered).length;
            this.updateNetwork();
        }
        this.setParam('size', this.size);
        this.setParam('version', this.version);
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(0);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    setSize(size) {
        this.newGame(size, this.version);
    }
    setVersion(version) {
        this.newGame(this.size, version);
    }
    newGame(size = undefined, version) {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                this.size = size || this.size;
                this.version = version || this.version;
                this.setParam('version', this.version);
                this.setParam('size', this.size);
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.makeNetwork();
            }
        });
    }
    restart() {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.empty = [];
                this.tips = [];
                this.stack = [];
                this.moves = 0;
                this.grid = ng.copy(this.gridInitial);
                this.connected = this.grid.filter((item) => item._powered).length;
            }
        });
    }
    makeNetwork() {
        this.empty = [];
        this.tips = [];
        this.stack = [];
        this.moves = 0;
        this.connected = 1;
        this.grid = [];
        this.gridInitial = [];
        for (let i = 0; i < Math.pow(this.size, 2); ++i) {
            let tile = new Tile([false, false, false, false], false, false, false);
            this.grid.push(tile);
            this.empty.push(true);
        }
        const center = Math.floor(this.size / 2);
        const rootIndex = center * this.size + center;
        this.empty[rootIndex] = false;
        this.grid[rootIndex].isServer = true;
        this.grid[rootIndex].powered = true;
        let numOfConnections = randint(1, 5);
        const c = [];
        for (let i = 0; i < numOfConnections; ++i) {
            c.splice(randint(0, c.length), 0, true);
        }
        for (let i = 0; i < 4 - numOfConnections; ++i) {
            c.splice(randint(0, c.length), 0, false);
        }
        this.grid[rootIndex].connections = c;
        if (this.grid[rootIndex].connections[0] === true) {
            this.tips.push(rootIndex - this.size);
            this.grid[rootIndex - this.size].connections[2] = true;
        }
        if (this.grid[rootIndex].connections[1] === true) {
            this.tips.push(rootIndex + 1);
            this.grid[rootIndex + 1].connections[3] = true;
        }
        if (this.grid[rootIndex].connections[2] === true) {
            this.tips.push(rootIndex + this.size);
            this.grid[rootIndex + this.size].connections[0] = true;
        }
        if (this.grid[rootIndex].connections[3] === true) {
            this.tips.push(rootIndex - 1);
            this.grid[rootIndex - 1].connections[1] = true;
        }
        for (const t of this.tips) {
            this.empty[t] = false;
        }
        this.updateNetwork();
        // console.log(this.tips)
        // console.log(this.vectorToMatrix([...this.empty]))
        while (this.tips.length > 0) {
            const trytip = this.tips[randint(0, this.tips.length)];
            this.empty[trytip] = false;
            let cc = 0;
            for (const c of this.grid[trytip].connections) {
                if (c === true)
                    cc++;
            }
            // console.log(trytip, this.grid[trytip].connections, {cc})
            if (cc === 3) {
                // don't do 4-way junctions
                // console.log("Oh no CCCCC");
                this.tips.splice(this.tips.indexOf(trytip), 1);
                // gridElement.children[trytip].classList.remove("bg-green-500");
                // gridElement.children[trytip].classList.add("bg-purple-500");
                continue;
            }
            const y = Math.floor(trytip / this.size);
            const x = trytip - y * this.size;
            // console.log("try tip", trytip, x, y);
            const neighs = [];
            const attempts = [];
            let aroundX = false;
            let aroundY = false;
            if (this.version == 2) {
                if (x == 0) {
                    aroundX = true;
                    attempts.push(trytip - 1 + this.size);
                }
                if (y == 0) {
                    aroundY = true;
                    attempts.push(Math.pow(this.size, 2) - (this.size - x));
                }
            }
            if ((x >= 1) && !aroundX) {
                //left
                attempts.push(trytip - 1);
            }
            if ((x < this.size - 1) && !aroundX) {
                //right
                attempts.push(trytip + 1);
            }
            if ((y >= 1) && !aroundY) {
                // top
                attempts.push(trytip - this.size);
            }
            if ((y < this.size - 1) && !aroundY) {
                //bottom
                attempts.push(trytip + this.size);
            }
            // }
            for (const a of attempts) {
                if (this.empty[a] === true) {
                    neighs.push(a);
                }
            }
            if (neighs.length > 0) {
                const extend = neighs[randint(0, neighs.length)];
                this.empty[extend] = false;
                // console.log("extending", extend);
                if ((extend - trytip) === 1) {
                    //right
                    this.grid[trytip].connections[1] = true;
                    this.grid[extend].connections[3] = true;
                }
                if ((extend - trytip) === (this.size - 1)) {
                    // console.log('right around')
                    //right around
                    this.grid[trytip].connections[3] = true;
                    this.grid[extend].connections[1] = true;
                }
                if ((trytip - extend) === 1) {
                    // left
                    this.grid[trytip].connections[3] = true;
                    this.grid[extend].connections[1] = true;
                }
                if ((extend - trytip) === this.size) {
                    // bottom
                    this.grid[trytip].connections[2] = true;
                    this.grid[extend].connections[0] = true;
                }
                if ((extend - trytip) === (Math.pow(this.size, 2) - this.size)) {
                    // console.log('top around')
                    // top around
                    this.grid[trytip].connections[0] = true;
                    this.grid[extend].connections[2] = true;
                }
                if ((trytip - extend) === this.size) {
                    // top
                    this.grid[trytip].connections[0] = true;
                    this.grid[extend].connections[2] = true;
                }
                this.tips.push(extend);
                // gridElement.children[extend].classList.remove("bg-gray-300");
                // gridElement.children[extend].classList.add("bg-green-500");
            }
            else {
                this.tips.splice(this.tips.indexOf(trytip), 1);
                // gridElement.children[trytip].classList.remove("bg-green-500");
                // gridElement.children[trytip].classList.add("bg-purple-500");
            }
            // p5.js debugging
            // for (let i = 0; i < empty.length; ++i) {
            //     yy = Math.floor(i / cols);
            //     xx = i - yy * cols;
            //     fill(empty[i] === true ? "green" : "red");
            //     square(xx * 48, yy * 48, 48);
            //     fill("blue");
            //     if (tips.indexOf(i) > -1) {
            //         circle(xx * 48 + 24, yy * 48 + 24, 24);
            //     }
            // }
        }
        this.gridResolved = ng.copy(this.grid);
        // перетосовка
        let totalSpin = 0;
        for (const t of this.grid) {
            if (this.grid.indexOf(t) !== rootIndex) {
                const spinny = randint(0, 4);
                for (let i = 0; i < spinny; ++i) {
                    //@ts-ignore
                    t.connections.push(t.connections.shift());
                }
                if (spinny === 1 || spinny === 2) {
                    totalSpin += spinny;
                }
                else if (spinny === 3) {
                    totalSpin += 1;
                }
            }
        }
        this.updateNetwork();
        this.gridInitial = ng.copy(this.grid);
        this.connected = this.grid.filter((item) => item._powered).length;
        // console.log(
        //     this.grid,
        //     this.empty,
        //     this.tips,
        // )
    }
    rotate($event, tileIndex, clockwise) {
        if (!this.gameFinish) {
            if (clockwise == 'clockwise') {
                //@ts-ignore
                this.grid[tileIndex].connections.unshift(this.grid[tileIndex].connections.pop());
            }
            else {
                //@ts-ignore
                this.grid[tileIndex].connections.push(this.grid[tileIndex].connections.shift());
            }
            this.moves++;
            this.updateNetwork();
            if (this.grid[tileIndex]._powered) {
                this.SoundService.play('powered');
            }
            else {
                this.SoundService.play('rotate');
            }
            this.connected = this.grid.filter((item) => item._powered).length;
            this.save();
        }
    }
    vectorToMatrix(arr) {
        const f = [];
        while (arr.length > 0) {
            f.push(arr.splice(0, this.size));
        }
        return f;
    }
    floor(a) {
        return Math.floor(a);
    }
    debug() {
        this.grid = ng.copy(this.gridResolved);
        this.rotate(new Event('click'), 0, 'clockwise');
        this.updateNetwork();
    }
    save() {
        localStorage.setItem(`${this.constructor.name}_game`, ng.toJson({
            grid: this.grid,
            gridInitial: this.gridInitial,
            gridResolved: this.gridResolved,
            size: this.size,
            version: this.version,
            moves: this.moves,
        }));
        this._startTimer.next(true);
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    endGame() {
        this._stopTimer.next(null);
        this.gameFinish = true;
        this.SoundService.play('win');
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                timeRemaining: this.timeRemaining,
                moves: this.moves,
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            localStorage.removeItem(`${this.constructor.name}_game`);
            localStorage.removeItem(`${this.constructor.name}_startWith`);
            if (result == 'newGame') {
                this.newGame();
            }
        });
    }
    setParam(name, value) {
        if (name == 'size') {
            if (value == defaultSize) {
                this.$location.search('size', null);
            }
            else {
                this.$location.search('size', value);
            }
        }
        else if (name == 'version') {
            if (value == defaultVersion) {
                this.$location.search('version', null);
            }
            else {
                this.$location.search('version', value);
            }
        }
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !localStorage.getItem(`${this.constructor.name}_game`)) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            const timerWork = this.timerWork;
            this._stopTimer.next(null);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    if (timerWork) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
    updateNetwork() {
        var _a;
        const center = Math.floor(this.size / 2);
        const rootIndex = center * this.size + center;
        for (const e of this.grid) {
            e.powered = false;
        }
        this.detectPowered(rootIndex);
        let poweredCount = 0;
        for (const e of this.grid) {
            if (e.powered === true) {
                poweredCount++;
            }
        }
        if (poweredCount === Math.pow(this.size, 2)) {
            this.endGame();
        }
        for (let i = 0; i < this.grid.length; ++i) {
            let bitmap = 0;
            if (this.grid[i].connections[0] === true) {
                bitmap += 1;
            }
            if (this.grid[i].connections[1] === true) {
                bitmap += 2;
            }
            if (this.grid[i].connections[2] === true) {
                bitmap += 4;
            }
            if (this.grid[i].connections[3] === true) {
                bitmap += 8;
            }
            const pathInfo = {};
            // future
            if ([1, 2, 4, 8].indexOf(bitmap) != -1) {
                pathInfo['path'] = 'halfline';
                pathInfo['rotate'] = getBaseLog(2, bitmap) * 90;
            }
            let xOffset = (bitmap - 1) * 48;
            let yOffset = 0;
            if (this.grid[i].isServer) {
                yOffset = 48 * 2;
            }
            else if (this.grid[i].powered) {
                yOffset = 48;
            }
            this.grid[i].position = [xOffset, yOffset];
            this.grid[i].pathInfo = {
                '384': { rotate: 0, path: 'corner' },
                '96': { rotate: 90, path: 'corner' },
                '240': { rotate: 180, path: 'corner' },
                '528': { rotate: 270, path: 'corner' },
                '432': { rotate: 0, path: 'line' },
                '192': { rotate: 90, path: 'line' },
                '480': { rotate: 0, path: 'bead' },
                '624': { rotate: 180, path: 'bead' },
                '576': { rotate: 270, path: 'bead' },
                '288': { rotate: 90, path: 'bead' },
                '0': { rotate: 90, path: 'halfline' },
                '-48': { rotate: 180, path: 'halfline' },
                '48': { rotate: 180, path: 'halfline' },
                '144': { rotate: 270, path: 'halfline' },
                '336': { rotate: 0, path: 'halfline' },
                '672': { rotate: 0, path: 'cross' },
            }[xOffset.toString()];
            // console.log(this.grid[i].pathInfo, xOffset)
            this.grid[i].isTerminal = (((_a = this.grid[i].pathInfo) === null || _a === void 0 ? void 0 : _a.path) == 'halfline');
            // console.log(xOffset.toString())
        }
    }
    detectPowered(tileIndex) {
        // dfs the network to see if each tile is powered
        this.stack.push(tileIndex);
        this.grid[tileIndex].powered = true;
        const y = Math.floor(tileIndex / this.size);
        const x = tileIndex - y * this.size;
        const adj = [];
        if (y >= 1 &&
            this.grid[tileIndex].connections[0] === true &&
            this.grid[tileIndex - this.size].connections[2] === true) {
            adj.push(tileIndex - this.size);
        }
        if (x < this.size - 1 &&
            this.grid[tileIndex + 1].connections[3] === true &&
            this.grid[tileIndex].connections[1] === true) {
            adj.push(tileIndex + 1);
        }
        if (y < this.size - 1 &&
            this.grid[tileIndex].connections[2] === true &&
            this.grid[tileIndex + this.size].connections[0] === true) {
            adj.push(tileIndex + this.size);
        }
        if (x >= 1 &&
            this.grid[tileIndex].connections[3] === true &&
            this.grid[tileIndex - 1].connections[1] === true) {
            adj.push(tileIndex - 1);
        }
        if (this.version == 2) {
            if (x == 0 &&
                this.grid[tileIndex].connections[3] === true &&
                this.grid[tileIndex - 1 + this.size].connections[1] === true) {
                adj.push(tileIndex - 1 + this.size);
            }
            if (x == this.size - 1 &&
                this.grid[tileIndex].connections[1] === true &&
                this.grid[tileIndex - (this.size - 1)].connections[3] === true) {
                adj.push(tileIndex - (this.size - 1));
            }
            if (y == 0 &&
                this.grid[tileIndex].connections[0] === true &&
                this.grid[Math.pow(this.size, 2) - (this.size - x)].connections[2] === true) {
                adj.push(Math.pow(this.size, 2) - (this.size - x));
            }
            if (y == this.size - 1 &&
                this.grid[tileIndex].connections[2] === true &&
                this.grid[tileIndex % this.size].connections[0] === true) {
                adj.push(tileIndex % this.size);
            }
        }
        for (const a of adj) {
            if (this.stack.indexOf(a) === -1) {
                this.detectPowered(a);
                this.stack.pop();
            }
        }
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem(`${this.constructor.name}_startWith`, sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
}
NetwalkCtrl.$inject = [
    '$scope',
    '$timeout',
    '$q',
    '$location',
    'ModalServiceFactory',
    'ConfigService',
    'SoundService'
];
const appModule = ng.module('app');
appModule.directive('ngRightClick', ngRightClick_1.NgRightClick);
appModule.component('gameNetwalk', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: NetwalkCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('netwalk/');
    }]);
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('netwalk/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'powered': require('./sounds/flag.mp3').default,
            'rotate': require('./sounds/hint.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            show_moves: true,
            sound_effects: false,
        });
    }]);
